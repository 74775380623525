/* eslint-disable */
export default {
  namespaced: true,
  state: {
    selectedLocation: null || localStorage.getItem("selectedLocation"),
  },
  mutations: {
    selectedLocation(state, payload) {
      localStorage.setItem("selectedLocation", JSON.stringify(payload));
      state.selectedLocation = payload;
    },
    clearSelectedLocation(state) {
      localStorage.removeItem("selectedLocation");
      state.selectedLocation = null;
    }
  },
  actions: {
    getLocations({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations")
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    createLocation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .post("/locations", payload)
          .then(res => {
            resolve(res.data.message);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    saveLocation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .put("/locations/" + payload.id, payload.data)
          .then(res => {
            resolve(res.data.message);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    deleteLocation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .delete("/locations/" + payload)
          .then(res => {
            resolve(res.data.message);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getLocationDetails({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            console.log(err);
            reject(console.error(err));
          });
      });
    },
    switchIrrigation({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .patch("/pumps/" + payload.id + "/switch", { status: payload.manual })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    },
    selectedLocation({ commit }, payload) {
      commit("selectedLocation", payload);
    },
    clearSelectedLocation({ commit }) {
      commit("clearSelectedLocation");
    }
  },
  getters: {
    selectedLocation(state) {
      return state.selectedLocation !== null
        ? typeof state.selectedLocation === "string"
          ? JSON.parse(state.selectedLocation)
          : state.selectedLocation
        : state.selectedLocation;
    },
  }
};
