<template>
  <canvas ref="myChart" width="120" height="100"></canvas>
</template>
<script>
import Chart from 'chart.js/auto'
export default {
  name: 'Sensor',
  props: ['chartData', 'options'],
  data () {
    return {
      chart: null,
      data: null
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    chartData (val) {
      this.chart.data.datasets[0].data = [val.value, 100 - val.value]
      this.chart.data.datasets[0].backgroundColor = [
        val.status === 'Normal' ? '#0e3d8e' : val.status === 'Warning' ? '#e59347' : val.status === 'Alert' ? '#f72b50' : '',
        'rgba(0, 0, 0, 0.2)'
      ]
      this.chart.update()
    }
  },
  methods: {
    init () {
      this.chart = new Chart(this.$refs.myChart.getContext('2d'), {
        type: 'doughnut',
        data:
        {
          datasets: [{
            label: '# of Votes',
            data: [this.chartData.value, 100 - this.chartData.value],
            backgroundColor: [
              this.chartData.status === 'Normal' ? '#0e3d8e' : this.chartData.status === 'Warning' ? '#e59347' : this.chartData.status === 'Alert' ? '#f72b50' : '',
              'rgba(0, 0, 0, 0.2)'
            ],
            borderColor: ['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],
            borderWidth: 3
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          rotation: 270,
          circumference: 180,
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          },
          cutout: '80%'
        }
      })
    }
  }
}
</script>
