<template>
  <div class="header">
    <div class="header-content">
      <nav class="navbar navbar-expand">
        <div class="collapse navbar-collapse justify-content-between">
          <div class="header-left">
            <div class="dashboard_bar">
              {{ pageTitle }}
            </div>
            <!-- <div class="nav-item d-flex align-items-center">
              <div class="input-group search-area">
                <input type="text" class="form-control" placeholder="">
                <span class="input-group-text"><a href="javascript:void(0)"><i
                      class="flaticon-381-search-2"></i></a></span>
              </div>
              <div class="plus-icon">
                <a href="javascript:void(0);"><i class="fas fa-plus"></i></a>
              </div>
            </div> -->
          </div>
          <ul class="navbar-nav header-right">
            <!-- <li class="nav-item dropdown notification_dropdown">
              <a class="nav-link" href="javascript:void(0);" role="button" data-bs-toggle="dropdown">
                <i class="far fa-bell header-icon"></i>
                <span class="badge light text-white bg-primary rounded-circle">4</span>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <div id="DZ_W_Notification1" class="widget-media dlab-scroll p-3" style="height:380px;">
                  <ul class="timeline">
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2">
                          <img alt="image" width="50" src="images/avatar/1.jpg">
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Dr sultads Send you Photo</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2 media-info">
                          KG
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Resport created successfully</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2 media-success">
                          <i class="fa fa-home"></i>
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Reminder : Treatment Time!</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2">
                          <img alt="image" width="50" src="images/avatar/1.jpg">
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Dr sultads Send you Photo</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2 media-danger">
                          KG
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Resport created successfully</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="timeline-panel">
                        <div class="media me-2 media-primary">
                          <i class="fa fa-home"></i>
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">Reminder : Treatment Time!</h6>
                          <small class="d-block">29 July 2020 - 02:26 PM</small>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <a class="all-notification" href="javascript:void(0);">See all notifications <i
                    class="ti-arrow-end"></i></a>
              </div>
            </li> -->
            <li class="nav-item">
              <a v-if="themeVer == 'dark'" class="nav-link" href="javascript:void(0);" @click="$emit('themeVer', 'light')">
                <i class="far fa-moon header-icon"></i>
              </a>
              <a v-else class="nav-link" href="javascript:void(0);" @click="$emit('themeVer', 'dark')">
                <i class="far fa-sun header-icon"></i>
              </a>
            </li>
            <li class="nav-item dropdown header-profile">
              <a class="nav-link" href="javascript:void(0);" role="button" data-bs-toggle="dropdown">
                <img v-if="userData" :src="userData.avatar" width="20" alt="" />
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <router-link to="/profile" class="dropdown-item ai-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  <span class="ms-2">Profile </span>
                </router-link>
                <a @click="logout()" class="dropdown-item ai-icon btn-logout">
                  <svg xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  <span class="ms-2">Logout </span>
                </a>
              </div>
            </li>
            <li class="nav-item">
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: 'base-header',
  props: ['pageTitle', 'themeVer'],
  computed: {
    userData () {
      return this.$store.getters['user/data']
    },
    theme () {
      return this.$store.getters['config/theme']
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('user/logout')
    }
  },
  watch: {
  }
}
</script>
<style scoped>
.btn-logout {
  cursor: pointer;
}

.header-profile .dropdown-menu a:hover {
  color: #393939;
}

.header-icon {
  font-size: 1.7rem !important;
}

.header-right .notification_dropdown .nav-link {
  color: #464a53;
}
</style>
