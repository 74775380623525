import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import config from '@/config'
import mixins from '@/mixins'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Moment from 'moment'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueDatePicker from '@mathieustan/vue-datepicker'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import VueExcelXlsx from 'vue-excel-xlsx'

Vue.use(VueExcelXlsx)
Vue.use(VueDatePicker)

/* Axios */
Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = config.apiUrl
Vue.axios.defaults.withCredentials = true
Vue.axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401 || error.response.status === 419) {
    store.dispatch('user/logout', error.response.data.message)
  } else if (error.response.status === 404) {
    router.push('/404')
  } else {
    /* console.error('axios error', error) */
  }
  return Promise.reject(error)
})

if (store.getters['user/isLoggedIn'] === true) {
  Vue.axios.defaults.headers = {
    Authorization: 'Bearer ' + store.getters['user/token'],
    Accept: 'application/json'
  }
}
Vuex.Store.prototype.axios = Vue.axios

/* Toast */
Vue.prototype.$toast = VueToast
Vue.use(VueToast, {
  position: 'bottom',
  duration: 5000
})

/* Google Map */
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCN77angDfFsPQOt244JZsC1Cr6rPzyhrc',
    libraries: 'places' // This is required if you use the Autocomplete plugin
  },
  installComponents: true
})

Vue.prototype.moment = Moment
Vue.prototype.$config = config
Vue.config.productionTip = false
Vue.mixin(mixins)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
