<template>
  <div class="dlabnav" >
    <div class="dlabnav-scroll">
      <!-- <div class="dropdown header-profile2 ">
        <a class="nav-link " href="javascript:void(0);" role="button" data-bs-toggle="dropdown">
          <div class="header-info2 d-flex align-items-center">
            <img v-if="userData" :src="userData.avatar" alt="" />
            <div class="d-flex align-items-center sidebar-info">
              <div>
                <span v-if="userData" class="font-w400 d-block">{{ userData.name }}</span>
                <small class="text-end font-w400">{{ role | capitalise }}</small>
              </div>
              <i class="fas fa-chevron-down"></i>
            </div>

          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <router-link to="/profile" class="dropdown-item ai-icon ">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            <span class="ms-2">Profile </span>
          </router-link>
          <a @click="logout()" class="dropdown-item ai-icon btn-logout">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7"></polyline>
              <line x1="21" y1="12" x2="9" y2="12"></line>
            </svg>
            <span class="ms-2">Logout </span>
          </a>
        </div>
      </div> -->
      <ul class="metismenu" id="menu">
        <template v-for="(menu, m) in menus" >
          <li v-show="menuShow(menu)" :key="m" :class="{ 'mm-active': isActive(menu) }">
            <template v-if="!('hasChild' in menu)">
              <router-link :to="menu.url" class="" aria-expanded="false">
                <i :class="menu.icon"></i>
                <span class="nav-text">{{ menu.name }}</span>
              </router-link>
            </template>
            <template v-else>
              <a class="has-arrow " href="javascript:void()" :aria-expanded="isActive(menu)">
                <i class="bi bi-sliders"></i>
                <span class="nav-text">{{ menu.name }}</span>
              </a>
              <ul aria-expanded="false" :class="[ isActive(menu) ? 'mm-collapse mm-show' : 'mm-collapse' ]">
                <li v-for="(submenu, s) in menu.submenus" :key="s" :class="{ 'mm-active': currentPath === submenu.url }">
                  <router-link :to="submenu.url" active-class="mm-active">{{ submenu.name }}</router-link>
                </li>
              </ul>
            </template>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'base-sidebar',
  data: () => {
    return {
      currentPath: null,
      menus: [
        {
          name: 'Dashboard',
          icon: 'flaticon-025-dashboard',
          url: '/dashboard'
        },
        {
          name: 'Settings',
          icon: 'bi bi-sliders',
          admin: true,
          hasChild: true,
          submenus: [
            {
              name: 'Users',
              url: '/settings/users'
            },
            {
              name: 'Locations',
              url: '/settings/locations'
            },
            {
              name: 'RTU',
              url: '/settings/rtu'
            }
          ]
        },
        {
          name: 'Sensor',
          icon: 'bi bi-pie-chart-fill',
          url: '/sensor'
        },
        {
          name: 'System',
          icon: 'bi bi-info-circle-fill',
          url: '/system'
        },
        {
          name: 'Photos',
          icon: 'bi bi-card-image',
          url: '/photos/gallery'
        },
        {
          name: 'Report',
          icon: 'bi bi-clipboard',
          url: '/report'
        },
        {
          name: 'Logs',
          icon: 'bi bi-card-list',
          hasChild: true,
          submenus: [
            {
              name: 'List',
              url: '/logs/list'
            },
            {
              name: 'Summary',
              url: '/logs/summary'
            }
          ]
        }
      ]
    }
  },
  computed: {
    userData () {
      return this.$store.getters['user/data']
    },
    isAdmin () {
      return this.$store.getters['user/role'] === 'administrator'
    },
    role () {
      return this.$store.getters['user/role']
    }
  },
  watch: {
    $route (to, from) {
      this.currentPath = to.path
    }
  },
  mounted () {
    this.currentPath = this.$router.currentRoute.path
  },
  methods: {
    logout () {
      this.$store.dispatch('user/logout')
    },
    menuShow (menu) {
      if ('admin' in menu) {
        return this.isAdmin
      } else {
        return true
      }
    },
    isActive (menu) {
      if (!('hasChild' in menu)) {
        return menu.url === this.currentPath
      } else {
        let flag = false
        menu.submenus.forEach(sm => {
          if (sm.url === this.currentPath) {
            flag = true
          }
        })
        return flag
      }
    }
  }
}
</script>
<style scoped>
/* .btn-logout {
  cursor: pointer;
}
.dlabnav .metismenu li ul a.mm-active {
  color: #fff !important;
  font-weight: 500;
} */
</style>
