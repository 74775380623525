<template>
  <div class="row">
    <div class="col-md-12 mb-3">Click on <i class="fas fa-check mx-1 text-primary"></i> button to enable the notification</div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">Moisture</h4>
        <div class="input-group me-2">
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.MoistureTrigMin">
            <span class="mt-1">Min</span>
          </span>
          <input type="number" v-model="valueTN.MoistureMin" placeholder="" :disabled="!editMode" class="form-control px-2" >
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.MoistureTrigMax" >
            <span class="mt-1">Max</span>
          </span>
          <input type="number" v-model="valueTN.MoistureMax"  :disabled="!editMode" placeholder="" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex  align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">Temperature</h4>
        <div class="input-group me-2">
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.TemperatureTrigMin">
            <span class="mt-1">Min</span>
          </span>
          <input type="number" v-model="valueTN.TemperatureMin" :disabled="!editMode" placeholder="" class="form-control px-2" >
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.TemperatureTrigMax" >
            <span class="mt-1">Max</span>
          </span>
          <input type="number" v-model="valueTN.TemperatureMax" :disabled="!editMode" placeholder="" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">pH</h4>
        <div class="input-group me-2">
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.PHTrigMin">
            <span class="mt-1">Min</span>
          </span>
          <input type="number" v-model="valueTN.PHMin" :disabled="!editMode" placeholder="" class="form-control px-2" >
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.PHTrigMax" >
            <span class="mt-1">Max</span>
          </span>
          <input type="number" v-model="valueTN.PHMax" :disabled="!editMode" placeholder="" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">N</h4>
        <div class="input-group me-2">
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.NTrigMin">
            <span class="mt-1">Min</span>
          </span>
          <input type="number" v-model="valueTN.NMin" :disabled="!editMode" placeholder="" class="form-control px-2" >
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.NTrigMax" >
            <span class="mt-1">Max</span>
          </span>
          <input type="number" v-model="valueTN.NMax" :disabled="!editMode" placeholder="" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">EC</h4>
        <div class="input-group me-2">
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.ECTrigMin">
            <span class="mt-1">Min</span>
          </span>
          <input type="number" v-model="valueTN.ECMin" :disabled="!editMode" placeholder="" class="form-control px-2" >
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.ECTrigMax" >
            <span class="mt-1">Max</span>
          </span>
          <input type="number" v-model="valueTN.ECMax" :disabled="!editMode" placeholder="" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">P</h4>
        <div class="input-group me-2">
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.PTrigMin">
            <span class="mt-1">Min</span>
          </span>
          <input type="number" v-model="valueTN.PMin" :disabled="!editMode" placeholder="" class="form-control px-2" >
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.PTrigMax" >
            <span class="mt-1">Max</span>
          </span>
          <input type="number" v-model="valueTN.PMax" :disabled="!editMode" placeholder="" class="form-control px-2" >
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 mb-3">
      <div class="d-md-flex align-items-center">
        <h4 class="mb-0 me-3" style="min-width: 110px">K</h4>
        <div class="input-group me-2">
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.KTrigMin">
            <span class="mt-1">Min</span>
          </span>
          <input type="number" v-model="valueTN.KMin" placeholder="" :disabled="!editMode" class="form-control px-2" >
          <span class="input-group-text d-flex align-items-center">
            <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="valueTN.KTrigMax" >
            <span class="mt-1">Max</span>
          </span>
          <input type="number" v-model="valueTN.KMax" placeholder="" :disabled="!editMode" class="form-control px-2" >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['valueTN', 'editMode'],
  data () {
    return {}
  }
}
</script>
