<template>
  <PageTemplate >
    <template slot="title">
      <a class="text-primary custom-link cursor-pointer" @click="changePage('/settings/locations')">Location Listing</a>
      <i class="fas fa-chevron-right ms-2 me-2 text-gray-500"></i>
      <span v-if="$route.path !== '/settings/locations/create'">Location Details</span>
      <span v-else>Create New Location</span>
    </template>
    <template slot="header-buttons" v-if="this.$route.params.id !== 'create'">
      <template v-if="!isMobileView">
        <a class="btn btn-primary me-3 btn-sm" v-if="!editmode" @click="editmode = true" :disabled="isLoading"><i class="fas fa-edit me-2 fa-lg"></i>EDIT</a>
        <a class="btn btn-primary me-3 btn-sm" v-if="editmode" @click="saveLocationDetails()" :disabled="isLoading"><i class="fas fa-save me-2 fa-lg"></i>SAVE</a>
        <a class="btn btn-danger btn-sm" v-if="editmode" @click="getDetails()" :disabled="isLoading"><i class="fas fa-times me-2 fa-lg" ></i>CANCEL</a>
        <a class="btn btn-danger btn-sm" ref="deleteDialogBtn" v-if="!editmode" :disabled="isLoading" @click="deleteDialog = true" data-bs-toggle="modal" data-bs-target="#closeDialog"><i class="fas fa-trash me-2"></i>Delete</a>
      </template>
      <div class="dropdown custom-dropdown mb-0" v-else >
        <div class="btn sharp tp-btn dark-btn" data-bs-toggle="dropdown">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="btn btn-primary me-3 btn-sm" v-if="!editmode" @click="editmode = true" :disabled="isLoading"><i class="fas fa-edit me-2 fa-lg"></i>EDIT</a>
            <a class="btn btn-primary me-3 btn-sm" v-if="editmode" @click="saveLocationDetails()" :disabled="isLoading"><i class="fas fa-save me-2 fa-lg"></i>SAVE</a>
            <a class="btn btn-danger btn-sm" v-if="editmode" @click="getDetails()" :disabled="isLoading"><i class="fas fa-times me-2 fa-lg" ></i>CANCEL</a>
            <a class="btn btn-danger btn-sm" ref="deleteDialogBtn" v-if="!editmode" :disabled="isLoading" @click="deleteDialog = true" data-bs-toggle="modal" data-bs-target="#closeDialog"><i class="fas fa-trash me-2"></i>Delete</a>
        </div>
      </div>
    </template>
    <template slot="content">
      <div :class="isMobileView ? '' : 'd-flex'">
        <Stepper :steppers="steppers" class="me-4 pb-4" />
        <div class=" flex-grow-1" :style="{width: isMobileView ? '100%' : 'calc(100% - 300px)'}">
          <div class="card"  ref="detailsWrapper">
            <div class="card-header">
              <h4 class="fs-20"><strong>{{steppers[$route.query.stepperActive].label}}</strong></h4>
            </div>
            <div class="card-body">
              <component
                :editMode="editmode"
                :is="steppers[$route.query.stepperActive].component"
                @addField="fields.push($event)"
                @deleteField="fields.splice($event, 1)"
                @addVariable="variables.push($event)"
                @deleteVariable="variables.splice($event, 1)"
                @addVariableRange="variables[$event.index].ranges.push($event.data)"
                @deleteVariableRange="variables[$event.index].ranges.splice($event.data, 1)"
                @addNoticationTel="notifications.phones.push($event)"
                @addNoticationEmail="notifications.emails.push($event)"
                @deleteNoticationTel="notifications.phones.splice($event, 1)"
                @deleteNoticationEmail="notifications.emails.splice($event, 1)"
                @addTime="pumps[$event.index].times.push($event.data)"
                @deleteTime="pumps[$event.no].times.splice($event.index, 1)"
                :valueRTU="rtu"
                :valueG ="general"
                :valueF="fields"
                :valueV="variables"
                :valueTN="thresholdNotifications"
                :valueN="notifications"
                :valueC="coefficients"
                :valueP="pumps" />
            </div>
            <div class="card-footer d-sm-flex justify-content-between align-items-center">
              <button class="btn btn-primary btn-sm"
                @click="prevForm()"
                v-if="parseInt($route.query.stepperActive) !== 0">
                <i class="fa fa-angle-double-left"></i> Previous
              </button>
              <div></div>
              <button class="btn btn-secondary btn-sm"
                @click="submitForm()"
                v-if="(steppers.length - 1) == parseInt($route.query.stepperActive) && this.$route.params.id == 'create'"
                :disabled="!steppers[$route.query.stepperActive].statusCompleted">
                Submit <i class="fa fa-save"></i>
              </button>
              <button
                class="btn btn-sm"
                :class="!steppers[$route.query.stepperActive].statusCompleted ? 'btn-light' : 'btn-primary'"
                @click="nextForm()"
                v-if="(steppers.length - 1) !== parseInt($route.query.stepperActive)"
                :disabled="!steppers[$route.query.stepperActive].statusCompleted">
                Next <i class="fa fa-angle-double-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--Dialog-->
        <Dialog :id="'closeDialog'" @close="deleteDialog = false" centered small title="Delete Location" >
          <template slot="content">
            <p>Are you sure want to delete?</p>
          </template>
          <template slot="footer">
            <a class="btn btn-danger btn-sm  light" :disabled="isLoading" data-bs-toggle="modal" data-bs-target="#closeDialog">cancel</a>
            <a class="btn btn-danger btn-sm" :disabled="isLoading" @click="deleteLocation">DELETE Location</a>
          </template>
        </Dialog>
    </template>
  </PageTemplate>
</template>
<script>
import PageTemplate from '@/components/Templates/PageTemplate'
import Stepper from '@/components/Templates/Stepper'
import General from '@/components/Forms/Locations/General'
import Fields from '@/components/Forms/Locations/Fields'
import Variables from '@/components/Forms/Locations/Variables'
import ThresholdNoti from '@/components/Forms/Locations/ThresholdNotification'
import Notification from '@/components/Forms/Locations/Notification'
import Coefficients from '@/components/Forms/Locations/Coefficient'
import Irrigation from '@/components/Forms/Locations/Irrigation'
import LocationRTU from '@/components/Forms/Locations/LocationRTU'
import Dialog from '@/components/Templates/Dialog'

export default {
  name: 'Location',
  components: {
    PageTemplate,
    Stepper,
    General,
    Fields,
    Variables,
    ThresholdNoti,
    Notification,
    Coefficients,
    Irrigation,
    LocationRTU,
    Dialog
  },
  data () {
    return {
      isLoading: true,
      editmode: false,
      deleteDialog: false,
      steppers: [
        {
          label: 'General Information',
          component: 'General',
          statusCompleted: false,
          firstEnter: false
        },
        {
          label: 'Fields',
          component: 'Fields',
          statusCompleted: false,
          firstEnter: false
        },
        {
          label: 'Variables',
          component: 'Variables',
          statusCompleted: false,
          firstEnter: false
        },
        {
          label: 'Threshold Notification',
          component: 'ThresholdNoti',
          statusCompleted: false,
          firstEnter: false
        },
        {
          label: 'Notification',
          component: 'Notification',
          statusCompleted: false,
          firstEnter: false
        },
        {
          label: 'Coefficient Factor',
          component: 'Coefficients',
          statusCompleted: false,
          firstEnter: false
        },
        {
          label: 'Irrigation',
          component: 'Irrigation',
          statusCompleted: false,
          firstEnter: false
        }
      ],
      general: {
        name: '',
        address: '',
        lat: null,
        lng: null
      },
      fields: [],
      additionalVariables: [],
      variables: [
        {
          name: 'Moisture',
          data_type: 'Decimal',
          decimal: '2',
          status: true,
          unit: '%',
          type: 'Fixed',
          ranges: []
        },
        {
          name: 'Temperature',
          data_type: 'Decimal',
          decimal: '2',
          status: true,
          unit: '°C',
          type: 'Fixed',
          ranges: []
        },
        {
          name: 'EC',
          data_type: 'Number',
          decimal: '1',
          status: true,
          unit: 'μS/cm',
          type: 'Fixed',
          ranges: []
        },
        {
          name: 'pH',
          data_type: 'Decimal',
          decimal: '2',
          status: true,
          unit: 'pH',
          type: 'Fixed',
          ranges: []
        },
        {
          name: 'N',
          data_type: 'Number',
          decimal: '1',
          status: true,
          unit: 'mg/kg',
          type: 'Fixed',
          ranges: []
        },
        {
          name: 'P',
          data_type: 'Number',
          decimal: '1',
          status: true,
          unit: 'mg/kg',
          type: 'Fixed',
          ranges: []
        },
        {
          name: 'K',
          data_type: 'Number',
          decimal: '1',
          status: true,
          unit: 'mg/kg',
          type: 'Fixed',
          ranges: []
        },
        /* {
          name: 'Coordinate',
          data_type: 'Text',
          decimal: '1',
          status: true,
          unit: '',
          type: 'Fixed',
          ranges: []
        }, */
        {
          name: 'GPRS',
          data_type: 'Number',
          decimal: '1',
          status: true,
          unit: '',
          type: 'Fixed',
          ranges: []
        },
        {
          name: 'Battery',
          data_type: 'Decimal',
          decimal: '2',
          status: true,
          unit: '',
          type: 'Fixed',
          ranges: []
        }
      ],
      thresholdNotifications: {
        MoistureTrigMin: false,
        MoistureTrigMax: false,
        MoistureMin: null,
        MoistureMax: null,
        TemperatureTrigMin: false,
        TemperatureTrigMax: false,
        TemperatureMin: null,
        TemperatureMax: null,
        ECTrigMin: false,
        ECTrigMax: false,
        ECMin: null,
        ECMax: null,
        PHTrigMin: false,
        PHTrigMax: false,
        PHMin: null,
        PHMax: null,
        NTrigMin: false,
        NTrigMax: false,
        NMin: null,
        NMax: null,
        PTrigMin: false,
        PTrigMax: false,
        PMin: null,
        PMax: null,
        KTrigMin: false,
        KTrigMax: false,
        KMin: null,
        KMax: null
      },
      notifications: {
        phones: [],
        emails: []
      },
      coefficients: {
        Moisture: '0',
        Temperature: '0',
        EC: '0',
        pH: '0',
        N: '0',
        P: '0',
        K: '0'
      },
      pumps: [
        {
          no: 1,
          status: false,
          days: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
          },
          sensor_mode: false,
          sensor: {
            moisture_greater_than: '0',
            moisture_less_than: '0'
          },
          timer_mode: false,
          times: []
        },
        {
          no: 2,
          status: false,
          days: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
          },
          sensor_mode: false,
          sensor: {
            moisture_greater_than: '0',
            moisture_less_than: '0'
          },
          timer_mode: false,
          times: []
        },
        {
          no: 3,
          status: false,
          days: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
          },
          sensor_mode: false,
          sensor: {
            moisture_greater_than: '0',
            moisture_less_than: '0'
          },
          timer_mode: false,
          times: []
        }
      ],
      rtu: null
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    }
  },
  created () {
    this.$emit('pageTitle', 'Locations')
    if (parseInt(this.$route.query.stepperActive) !== 0) {
      this.$router.push({
        query: { stepperActive: 0 }
      })
    }
    if (this.$route.params.id !== 'create') {
      this.getDetails()

      // add RTU Stepper on view details
      this.steppers.push({
        label: 'RTU Details',
        component: 'LocationRTU',
        statusCompleted: false,
        firstEnter: false
      })
    } else this.editmode = true
  },
  watch: {
    general: {
      deep: true,
      handler (val) {
        const notComplete = Object.values(val).some(x => x === null || x === '')
        this.steppers[0].statusCompleted = !notComplete
      }
    },
    fields: {
      deep: true,
      handler (val) {
        if (val.length) {
          for (let i = 0; i < val.length; i++) {
            const notComplete = Object.values(val[i]).some(x => x === null || x === '')
            if (notComplete) {
              this.steppers[1].statusCompleted = false
              return true
            } else {
              this.steppers[1].statusCompleted = true
            }
          }
        } else {
          this.steppers[1].statusCompleted = true
        }
      }
    },
    pumps: {
      deep: true,
      handler (val) {
        if (val.length) {
          for (let i = 0; i < val.length; i++) {
            const notComplete = Object.values(val[i]).some(x => x === null || x === '')
            if (notComplete) {
              this.steppers[6].statusCompleted = false
              return true
            } else {
              this.steppers[6].statusCompleted = true
            }
          }
        } else {
          this.steppers[6].statusCompleted = true
        }
      }
    },
    variables: {
      deep: true,
      handler (val) {
        if (val.length) {
          for (let i = 0; i < val.length; i++) {
            if (!val[i].name || !val[i].data_type || !val[i].type) {
              this.steppers[2].statusCompleted = false
              return true
            } else {
              if (val[i].data_type !== 'Text' && val[i].ranges.length) {
                for (let v = 0; v < val[i].ranges.length; v++) {
                  const notComplete = Object.values(val[i].ranges[v]).some(x => x === null || x === '')
                  if (notComplete) {
                    this.steppers[2].statusCompleted = false
                    return true
                  } else this.steppers[2].statusCompleted = true
                }
              } else this.steppers[2].statusCompleted = true
            }
          }
        } else this.steppers[2].statusCompleted = true
      }
    },
    coefficients: {
      deep: true,
      handler (val) {
        const notComplete = Object.values(val).some(x => x === null || x === '')
        this.steppers[5].statusCompleted = !notComplete
      }
    },
    '$route.query.stepperActive' (val) {
      if (parseInt(val) === 1 && !this.steppers[1].firstEnter) {
        this.steppers[1].statusCompleted = true
        this.steppers[1].firstEnter = true
      }
      if (parseInt(val) === 2 && !this.steppers[2].firstEnter) {
        this.steppers[2].statusCompleted = true
        this.steppers[2].firstEnter = true
      }
      if (parseInt(val) === 3 && !this.steppers[3].firstEnter) {
        this.steppers[3].statusCompleted = true
        this.steppers[3].firstEnter = true
      }
      if (parseInt(val) === 4 && !this.steppers[4].firstEnter) {
        this.steppers[4].statusCompleted = true
        this.steppers[4].firstEnter = true
      }
      if (parseInt(val) === 4 && !this.steppers[4].firstEnter) {
        this.steppers[4].statusCompleted = true
        this.steppers[4].firstEnter = true
      }
      if (parseInt(val) === 5 && !this.steppers[5].firstEnter) {
        this.steppers[5].statusCompleted = true
        this.steppers[5].firstEnter = true
      }
      if (parseInt(val) === 6 && !this.steppers[6].firstEnter) {
        this.steppers[6].statusCompleted = true
        this.steppers[6].firstEnter = true
      }
    }
  },
  methods: {
    testChangeStatus () {
      this.steppers[1].statusCompleted = false
    },
    getDetails () {
      this.loading(this.$refs.detailsWrapper, 'show')
      this.$store.dispatch('locations/getLocationDetails', this.$route.params.id).then(res => {
        const data = res.data
        this.general.name = data.name
        this.general.address = data.address
        this.general.lat = data.lat
        this.general.lng = data.lng
        this.fields = data.fields
        this.variables = data.variables
        this.thresholdNotifications = data.threshold_notifications
        this.notifications = data.notifications
        this.coefficients = data.coefficients
        this.pumps = data.pumps
        this.rtu = data.rtu

        // completed all form
        this.steppers.forEach(e => {
          e.statusCompleted = true
          e.firstEnter = true
        })
        this.editmode = false
        this.loading(this.$refs.detailsWrapper, 'hide')
      })
    },
    nextForm () {
      this.$router.push({
        query: { stepperActive: parseInt(this.$route.query.stepperActive) + 1 }
      })
    },
    prevForm () {
      this.$router.push({
        query: { stepperActive: parseInt(this.$route.query.stepperActive) - 1 }
      })
    },
    submitForm () {
      const payload = {
        name: this.general.name,
        address: this.general.address,
        lat: this.general.lat,
        lng: this.general.lng,
        fields: this.fields,
        variables: this.variables,
        thresholdNotifications: this.thresholdNotifications,
        notifications: this.notifications,
        coefficients: this.coefficients,
        pumps: this.pumps
      }
      this.loading(this.$refs.detailsWrapper, 'show')
      this.isLoading = true
      this.$store.dispatch('locations/createLocation', payload)
        .then(res => {
          this.$toast.success(res)
          this.loading(this.$refs.detailsWrapper, 'hide')
          this.isLoading = false
          this.$router.push('/settings/locations')
        })
        .catch(err => {
          console.log(err.response)
          this.$toast.error(err.response.data.error)
          this.loading(this.$refs.detailsWrapper, 'hide')
        })
    },
    saveLocationDetails () {
      const payload = {
        id: this.$route.params.id,
        data: {
          name: this.general.name,
          address: this.general.address,
          lat: this.general.lat,
          lng: this.general.lng,
          fields: this.fields,
          variables: this.variables,
          thresholdNotifications: this.thresholdNotifications,
          notifications: this.notifications,
          coefficients: this.coefficients,
          pumps: this.pumps
        }
      }
      this.loading(this.$refs.detailsWrapper, 'show')
      this.isLoading = true
      this.$store.dispatch('locations/saveLocation', payload)
        .then(res => {
          this.$toast.success(res)
          this.loading(this.$refs.detailsWrapper, 'hide')
          this.isLoading = false
          this.$router.push('/settings/locations')
        })
        .catch(err => {
          console.log(err.response.data.error)
          this.$toast.error(err.response.data.error)
          this.loading(this.$refs.detailsWrapper, 'hide')
        })
    },
    deleteLocation () {
      this.loading(this.$refs.detailsWrapper, 'show')
      this.isLoading = true
      this.$store.dispatch('locations/deleteLocation', this.$route.params.id)
        .then(res => {
          this.$toast.success(res)
          this.loading(this.$refs.detailsWrapper, 'hide')
          this.isLoading = false
          this.$router.push('/settings/locations')
          this.$refs.deleteDialogBtn.click()
        })
        .catch(err => {
          console.log(err)
          this.loading(this.$refs.detailsWrapper, 'hide')
        })
    }
  }
}
</script>
