<template>
  <div>
    <div class="row">
      <div class="col-xl-12  col-md-12">
        <div v-for="(item, i) in valueV" :key="i + 'main'" class="mb-3">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-between align-items-center" >
              <h4 class="mb-0">
                {{item.name}}
              </h4>
              <a class="btn tp-btn btn-danger rounded btn-sm" @click="deleteVariable(i)" v-if="item.type == 'Additional' && editMode"><i class="fas fa-times me-2"></i> remove</a>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 py-3" >
              <label  class="form-label font-w600">Status<span class="text-danger scale5 ms-2"> *</span></label>
              <div class="pt-3 pb-2">
                <label class="radio-inline me-3"><input v-model="item.status" :disabled="!editMode" :value="false" type="radio" /> Disable</label>
                <label class="radio-inline me-3"><input v-model="item.status" :disabled="!editMode" :value="true" type="radio" /> Enable</label>
              </div>
            </div>
            <div class="col-xl-5 col-lg-12 col-md-12 py-3" >
              <label  class="form-label font-w600">Data Type<span class="text-danger scale5 ms-2">*</span></label>
              <div class="pt-3 pb-2">
                <label class="radio-inline me-3" v-for="(list, ind) in types" :key="ind + list">
                  <input v-model="item.data_type" :disabled="!editMode || item.type !== 'Additional'" :value="list" type="radio" />
                  {{list}}
                </label>
              </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 pt-3 pb-3" v-if="item.data_type =='Decimal'" >
              <label  class="form-label font-w600">Decimal</label>
              <input type="number" v-model="item.decimal" :disabled="!editMode || item.type !== 'Additional'" placeholder="" class="form-control " >
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 pt-3 pb-3" >
              <label  class="form-label font-w600">Unit</label>
              <input type="text" v-model="item.unit" :disabled="!editMode || item.type !== 'Additional'" placeholder="" class="form-control " >
            </div>
          </div>
          <div class="row" v-if="item.type == 'Fixed' && item.data_type !== 'Text'">
            <div class="col-12" >
              <label  class="form-label font-w600">Ranges<span class="text-danger scale5 ms-2"> *</span></label>
            </div>
            <div class="col-12" >
              <div class="input-group mb-2" v-if="editMode">
                <span class="input-group-text bg-primary text-white " style="max-width: 50px"><strong>ADD</strong></span>
                <span class="input-group-text">Min</span>
                <input type="number" class="form-control" v-model="min" >
                <span class="input-group-text">Max</span>
                <input type="number" class="form-control" v-model="max" >
                <span class="input-group-text">Status</span>
                <select :disabled="!editmode" class="form-select form-control" v-model="statusR">
                  <option :selected="statusR == null ? true : false" :value="null">Choose...</option>
                  <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                </select>
                <span class="input-group-text py-0 bg-primary">
                  <button class="btn btn-sm tp-btn btn-rounded text-white" @click="addRange(i)" :disabled="!min || !max || !statusR"><i class="fa fa-plus"></i></button>
                </span>
              </div>
              <div class="input-group mb-2" :class="!rangeList.min || !rangeList.max || !rangeList.status ? 'input-danger-o' : ''" v-for="(rangeList, index) in item.ranges" :key="index + 'rg'">
                <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>{{index + 1}}</strong></span>
                <span class="input-group-text">Min</span>
                <input type="number" :disabled="!editMode" class="form-control" v-model="rangeList.min" >
                <span class="input-group-text">Max</span>
                <input type="number" :disabled="!editMode" class="form-control" v-model="rangeList.max" >
                <span class="input-group-text">Status</span>
                <select :disabled="!editMode" class="form-select form-control" v-model="rangeList.status">
                  <option :selected="rangeList.status== null ? true : false" :value="null">Choose...</option>
                  <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                </select>
                <span class="input-group-text bg-danger py-0" v-if="editMode">
                  <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteVariableRange(i, index)"><i class="fas fa-times"></i></button>
                </span>
              </div>
              <div v-if="!item.ranges.length" class="text-center p-3 text-light form-control"><em>No Range Added</em></div>
            </div>
           <!--  <div class="col-xl-4 col-lg-6 col-md-12" v-for="(el, i) in item.ranges" :key="i + 'range'">
              <div class="input-group mb-2" >
                <span class="input-group-text py-0 text-white" :class="'bg-' + (el.status == 'Warning' ? 'warning' : el.status == 'Alert' ? 'danger' : 'primary')" style="min-width: 75px">
                  {{el.status}}
                </span>
                <span class="input-group-text">Min</span>
                <input type="number" v-model="el.min" :disabled="!editMode" placeholder="" class="form-control px-2 text-center" >
                <span class="input-group-text">Max</span>
                <input type="number" v-model="el.max" :disabled="!editMode" placeholder="" class="form-control px-2 text-center" >
              </div>
            </div> -->
          </div>
          <hr v-if="valueV.length - 1 !== i" />
        </div>
      </div>
      <div class="col-md-12 mt-4 text-center" v-if='editMode' >
        <button class="btn btn-outline-primary m-auto" @click="addNewDialog = true" ref="addDialogBtn" data-bs-toggle="modal" data-bs-target="#addDialog">
          <i class="fas  fa-plus me-2"></i>
          Add Additional Sensor
        </button>
      </div>
    </div>

    <!--Dialog-->
    <Dialog :id="'addDialog'" @close="addNewDialog = false" centered large title="Add Additional Sensor" >
      <template slot="content">
        <div class="row">
          <div class="col-md-12 py-3" >
            <label  class="form-label font-w600">Sensor Name<span class="text-danger scale5 ms-2"> *</span></label>
            <input v-model="form.name" class="form-control px-2" />
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 py-3" >
            <label  class="form-label font-w600">Status<span class="text-danger scale5 ms-2"> *</span></label>
            <div class="pt-3 pb-2">
              <label class="radio-inline me-3"><input v-model="form.status" :value="false" type="radio" /> Disable</label>
              <label class="radio-inline me-3"><input v-model="form.status" :value="true" type="radio" /> Enable</label>
            </div>
          </div>
          <div class="col-xl-5 col-lg-12 col-md-12 py-3" >
            <label  class="form-label font-w600">Data Type<span class="text-danger scale5 ms-2">*</span></label>
            <div class="pt-3 pb-2">
              <label class="radio-inline me-3" v-for="(list, ind) in types" :key="ind + list">
                <input v-model="form.data_type" :value="list" type="radio" />
                {{list}}
              </label>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 pt-3 pb-3" v-if="form.data_type =='Decimal'" >
              <label  class="form-label font-w600">Decimal</label>
              <input type="number" v-model="form.decimal" placeholder="" class="form-control " >
            </div>
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 pt-xl-3 pb-md-3" >
            <label  class="form-label font-w600">Unit</label>
            <input type="text" v-model="form.unit" placeholder="" class="form-control" >
          </div>
        </div>
      </template>
      <template slot="footer">
        <a class="btn btn-danger btn-sm  light" ref="closeDialogBtn" data-bs-toggle="modal" data-bs-target="#addDialog">cancel</a>
        <a class="btn btn-primary btn-sm" :disabled="!Object.values(this.form).some(x => x === null || x === '')" @click="addVariable">Add Sensor</a>
      </template>
    </Dialog>
  </div>
</template>
<script>
import Dialog from '@/components/Templates/Dialog'
export default {
  props: ['valueV', 'editMode'],
  components: {
    Dialog
  },
  data () {
    return {
      editmode: true,
      addNewDialog: false,
      form: {
        name: '',
        data_type: 'Text',
        status: null,
        unit: '',
        type: 'Additional',
        decimal: 1
      },
      min: null,
      max: null,
      statusR: null,
      statusRange: [
        'Normal',
        'Warning',
        'Alert'
      ],
      types: [
        'Number',
        'Decimal',
        'Boolean',
        'Text'
      ]
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    }
  },
  created () {
  },
  watch: {
    'form.data_type' (val) {
    }
  },
  methods: {
    addVariable () {
      if (this.form.name && this.form.data_type && this.form.status !== null && this.form.type) {
        this.$emit('addVariable', {
          name: this.form.name,
          data_type: this.form.data_type,
          status: this.form.status,
          unit: this.form.unit,
          type: this.form.type,
          decimal: this.form.decimal
        })
        this.$refs.closeDialogBtn.click()
      }
    },
    deleteVariable (i) {
      this.$emit('deleteVariable', i)
    },
    addRange (i) {
      if (this.min && this.max && this.statusR) {
        const payload = {
          index: i,
          data: {
            min: this.min,
            max: this.max,
            status: this.statusR
          }
        }
        this.$emit('addVariableRange', payload)
        this.min = null
        this.max = null
        this.statusR = null
      }
    },
    deleteVariableRange (i, index) {
      this.$emit('deleteVariableRange', { index: i, data: index })
    }
  }
}
</script>

<style>

</style>
