/* eslint-disable */
export default {
  state: {},
  mutations: {},
  actions: {
    getSensorStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/locations/" + payload + "/live")
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getSensorData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/data", { params: payload })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getReportData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/data", { params: payload })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getLiveData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        this.axios
          .get("/live", { params: payload })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  getters: {}
};
