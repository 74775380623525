/* eslint-disable */
export default {
  namespace: true,
  state: {},
  mutations: {},
  actions: {
    getLogs({ commit }, payload) {
      const req = {
        location_id: payload.location_id,
        sort: payload.sort
      };
      if (payload.via) req.via = payload.via
      if (payload.start || payload.end) {
        req.start = payload.start
        req.end = payload.end
      }
      return new Promise((resolve, reject) => {
        this.axios
          .get(payload.url ? payload.url : "/logs", { params: req })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getLogsSummary({ commit }, payload) {
      const req = {
        location_id: payload.location_id,
      };
      if (payload.via) req.via = payload.via
      if (payload.dailyDate) req.daily_date = payload.dailyDate;
      if (payload.monthlyDate) req.monthly_date = payload.monthlyDate;
      return new Promise((resolve, reject) => {
        this.axios
          .get("/logs/summary", { params: req })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  getters: {}
};
